import { useEffect, useRef, useState } from "react";
import Header from "../../components/Header/PC";
import { linkWallet, NxWeb3 } from "../../libs/NxWeb3";
import { getPlaceholderStr, showToast } from "../../libs/utils";
import { isMobile } from "../../libs/userAgent";
import './index.scss'
import MobileHeader from "../../components/Header/Mobile";
import { render } from "@testing-library/react";
declare var window: any
const PCMintComp = (): JSX.Element => {
  // const [showSelectBag, setShowSelectBag] = useState(false)

  const [address, setAddress] = useState('');
  const selectType = useRef('')
  const [isConnected, setIsConnected] = useState(false);
  const [totalSupply, setTotalSupply] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [Quantity, setQuantity] = useState(3)
 
  useEffect(() => {
    try {
      NxWeb3.instance.killWalletConnect();
    } catch (_) { }
  }, []);

  useEffect(() => {
    const getCount = () => {
      NxWeb3.instance.getTotalSupply().then((res) => {
        setTotalSupply(res);
      });
    }
    getCount();
    const timer: NodeJS.Timeout = setInterval(() => {
      getCount();
    }, 120000);
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    }
  }, []);


  const connectMetaMask = async () => {
    const result = await linkWallet(() => {
      setAddress(NxWeb3.instance.accountAddress);
    })
    setIsConnected(result);
    if (NxWeb3.instance.isInited) {
      setAddress(NxWeb3.instance.accountAddress);
    }
  }

  const mint = async () => {
    // if (isLoading) return;

    if (!address || !isConnected) {
      // showToast('Plase connect wallet.', 0);
      connectMetaMask()
      return;
    }

    // if (NxWeb3.instance.connectType === 1) {
      setIsLoading(true);
      // const result = await NxWeb3.instance.preMint(1);
      const result = await NxWeb3.instance.publicMint(Quantity);
      if (result === true) {
        showToast('Successed.', 1);
      } else {
        showToast(result.message, 0);
      }
      setIsLoading(false);
    // } else {
    //   setIsLoading(true);
    //   await NxWeb3.instance.walletPreMint(1, () => {
    //     console.log('Successed.');
    //     showToast('Successed.', 1);
    //   }, (content: string = 'Failed.') => {
    //     console.log('Failed.');
    //     showToast(content, 0);
    //   })
    //   setIsLoading(false);
    // }
  }

  const hideAddress = (str:string)=>{

    return str.substr(0,5)+"****"+str.substr(str.length-4);  
  }

  return (
    <>
      <Header/>
      <div className="mintPage">
        <div className="container">
          <div className="mint_box_con">
          </div>
          <div className="mint_video">
            <video className="mint_info"  preload="auto" loop muted playsInline autoPlay>
              <source src={require('../../assets/images/coin_bg.mp4').default} type="video/mp4" />
            </video>
          </div> 
          <div className="mint_block">
            <div className="mint_com">
              <div className="mint_bg"></div>
              <div className="mint_content">
                <div className="text">
                  <p>Sale Status: Closed<br/><span className="num"><span className="sale">{totalSupply}</span>/3333</span></p>
                  <div className="box">
                    {/* {
                      address !==''
                      ?<>
                        <h6 className="address">{address !==''? hideAddress(address):''}</h6>
                        <div className="count">
                          <div className="inputGroup">
                            <button onClick={() => { if (Quantity > 1) { setQuantity(Quantity - 1) } }}>-</button>
                            <input type="text" readOnly value={Quantity} />
                            <button onClick={() => { if (Quantity < 5) { setQuantity(Quantity + 1) } }}>+</button>
                          </div>
                        </div>
                      </>
                      :''
                    }
                     */}
                    
                  </div>
                  {/* <button className="btn" onClick={mint} >
                  {address ===''?'Connect':isLoading?<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>:'Mint'}
                  </button> */}
                  <button className="btn" onClick={
                    ()=>{
                      window.location.href= 'https://opensea.io/collection/puggy-squad'
                    }
                  }>Buy on Opensea</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


const MobileMintComp = ():JSX.Element => {
   // const [showSelectBag, setShowSelectBag] = useState(false)

   const [address, setAddress] = useState('');
   const selectType = useRef('')
   const [isConnected, setIsConnected] = useState(false);
   const [totalSupply, setTotalSupply] = useState(0);
   const [isLoading, setIsLoading] = useState(false);
   const [Quantity, setQuantity] = useState(3)
  
   useEffect(() => {
     try {
       NxWeb3.instance.killWalletConnect();
     } catch (_) { }
   }, []);
 
   useEffect(() => {
     const getCount = () => {
       NxWeb3.instance.getTotalSupply().then((res) => {
         setTotalSupply(res);
       });
     }
     getCount();
     const timer: NodeJS.Timeout = setInterval(() => {
       getCount();
     }, 120000);
     return () => {
       if (timer) {
         clearInterval(timer);
       }
     }
   }, []);
 
 
   const connectMetaMask = async () => {
     const result = await linkWallet(() => {
       setAddress(NxWeb3.instance.accountAddress);
     })
     setIsConnected(result);
     if (NxWeb3.instance.isInited) {
       setAddress(NxWeb3.instance.accountAddress);
     }
   }
 
   const mint = async () => {
     // if (isLoading) return;
 
     if (!address || !isConnected) {
       // showToast('Plase connect wallet.', 0);
       connectMetaMask()
       return;
     }
 
     // if (NxWeb3.instance.connectType === 1) {
       setIsLoading(true);
       // const result = await NxWeb3.instance.preMint(1);
       const result = await NxWeb3.instance.publicMint(Quantity);
       console.log('%c🀀 result', 'color: #731d6d; font-size: 20px;', result);
       if (result === true) {
         showToast('Successed.', 1);
       } else {
         showToast(result.message, 0);
       }
       setIsLoading(false);
     // } else {
     //   setIsLoading(true);
     //   await NxWeb3.instance.walletPreMint(1, () => {
     //     console.log('Successed.');
     //     showToast('Successed.', 1);
     //   }, (content: string = 'Failed.') => {
     //     console.log('Failed.');
     //     showToast(content, 0);
     //   })
     //   setIsLoading(false);
     // }
   }
 
   const hideAddress = (str:string)=>{
 
     return str.substr(0,5)+"****"+str.substr(str.length-4);  
   }
  return (
    <>
      <div className="mobileMint">
      <div className="container">
          <div className="mint_block">
            <div className="mint_com">
              <div className="mint_bg"></div>
              <div className="mint_content">
                <div className="text">
                  <p>Sale Status: Closed<br/><span className="num"><span className="sale">{totalSupply}</span>/3333</span></p>
                  {/* <div className="box">
                    {
                      address !==''
                      ?<>
                        <h6 className="address">{address !==''? hideAddress(address):''}</h6>
                        <div className="count">
                          <div className="inputGroup">
                            <button onClick={() => { if (Quantity > 1) { setQuantity(Quantity - 1) } }}>-</button>
                            <input type="text" readOnly value={Quantity} />
                            <button onClick={() => { if (Quantity < 3) { setQuantity(Quantity + 1) } }}>+</button>
                          </div>
                        </div>
                      </>
                      :''
                    }
                    
                    
                  </div> */}
                  <button className="mint_btn" onClick={
                    ()=>{
                      window.location.href= 'https://opensea.io/collection/puggy-squad'
                    }
                  }>Buy on Opensea</button>
                 
                </div>
              </div>

              {/* <button className="mint_btn" onClick={mint} >
                {address ===''?'Connect':isLoading?<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>:'Mint'}
                </button> */}
             
            </div>
          </div>
        </div>
      </div>
    </>
  )
}



const MintComp = ():JSX.Element=>{
  return (
      <>
          {
          isMobile()? 
          <>
              <MobileHeader/>
              <MobileMintComp/>
          </>
          : 
          <>
              <Header></Header>
              <PCMintComp/>
          </>}
      </>
  );
}

export default MintComp;