import { useState } from "react";
import { Link } from "react-router-dom";
import "./index.scss";
import mobileCloseImage from '../../../assets/images/mobile-close.png'
import mobileMoreImage from '../../../assets/images/mobile-open.png'
import openseaIcon from '../../../assets/images/opensea.png'
import twitterIcon from '../../../assets/images/twitter.png'

// interface Props {
//   to: string,
//   callback: () => void,
//   children?: ReactNode,
//   id: string,
// }

// const NavItem = ({ to, callback, children, id }: Props): JSX.Element => {

//   const goToPage = () => {
//     const dom = document.getElementById(to);
//     if (dom) {
//       dom.scrollIntoView({ behavior: "smooth", });
//     }
//     callback && callback();
//   }

//   return (
//     <li className={`navlist-item`} id={id} onClick={goToPage}>
//       {children}
//     </li>
//   );
// }


export type MobileNavProps = {}

type MoreOrCloseType = 'close' | 'more'

export type MoreOrCloseProps = {
    state: MoreOrCloseType,
    onStateChange?: (_state: MoreOrCloseType)=>void,
}
 type SizedBoxProps = {
  width?: string,
  height?: string,
  children?: JSX.Element | JSX.Element[] | string
}

 const SizedBox = (props: SizedBoxProps):JSX.Element=>{
  const {width, height, children,} = props;
  return (
            <div style={{
                width: width ?? 0,
                height: height ?? 0,
                display: 'inline-block',
            }}>
                {children}
            </div>
  )
}

const MoreOrClose = (props: MoreOrCloseProps):JSX.Element=>{
  const { state, onStateChange } = props;
  
  return (
      <>
          <div className="morebtn" style={{
              backgroundImage:`url(${state === 'close' ? mobileMoreImage : mobileCloseImage})`,
              backgroundPosition:'center center',
              backgroundRepeat: 'no-repeat',
              backgroundSize:'cover',
              width: '.44rem',
              height: '.44rem',
          }} onClick={()=>{
              state === 'close' ? onStateChange && onStateChange('more') : onStateChange && onStateChange('close')
          }}/>
          
      </>
  )
}

const MobileHeader = (): JSX.Element => {
  // const [navBtn, setNavBtnClassNames] = useState('iconUl menu');
  // const [navModel, setNavModelClassNames] = useState('nav-model');

  // const changeNavStatus = () => {
  //   const isActive = navBtn.indexOf('active');
  //   if (isActive < 0) {
  //     setNavBtnClassNames('iconUl menu active opened');
  //     setNavModelClassNames('nav-model active');
  //     document.getElementsByTagName('body')[0].classList.add('overFlow')
  //   } else {
  //     setNavBtnClassNames('iconUl menu');
  //     setNavModelClassNames('nav-model');
  //     document.getElementsByTagName('body')[0].classList.remove('overFlow')
  //   }
  // }

  // const liClick = () => {
  //   setNavBtnClassNames('iconUl menu');
  //   setNavModelClassNames('nav-model');
  //   document.getElementsByTagName('body')[0].classList.remove('overFlow')
  // }

  const [_state, set_state] = useState<MoreOrCloseType>('close');

  return (
      <>
          <div className="mobileHeader">
              <div className="container">
                  <Link to={'/'} className="logo mobile">DummyPuggy</Link>
                  <div style={{
                    flexGrow: 1,
                  }}></div>
                  
                  <MoreOrClose state={_state} onStateChange={(_s)=>{
                      console.log(_s)
                      set_state(_s)
                  }}/>
                  {/* <SizedBox width=""/> */}
              </div>
              
          </div>
          {
              _state === 'more'? <div style={{
                  zIndex: 10,
                  background: `rgba(0,0,0,.8)`,
                  position: 'fixed',
                  inset: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  // justifyContent: 'center',
                  paddingTop: '1.4rem',
                  fontFamily: 'MEBlack',
                  fontSize: '.24rem',
                  lineHeight: 1.1
              }}>
                  <a style={{
                      height:64,
                      display: 'flex',
                      justifyContent: 'start',
                      textDecoration: 'none',
                  }} href="/">
                      <SizedBox width=".3rem"/>
                      <h6 style={{
                          color: '#fff',
                          lineHeight: '.64rem',
                          textTransform: 'uppercase',
                      }}>home</h6>
                  </a>

                  {/* <a style={{
                      height:64,
                      display: 'flex',
                      justifyContent: 'start',
                      textDecoration: 'none',
                  }} href="/planet">
                      <SizedBox width=".3rem"/>
                      <h6 style={{
                          color: '#fff',
                          lineHeight: '.64rem',
                          textTransform: 'uppercase',
                      }}>planet</h6>
                  </a> */}
                  <a style={{
                      height:64,
                      display: 'flex',
                      justifyContent: 'start',
                      textDecoration: 'none',
                  }} href="/mint">
                      <SizedBox width=".3rem"/>
                      <h6 style={{
                          color: '#fff',
                          lineHeight: '.64rem',
                          textTransform: 'uppercase',
                      }}>Mint</h6>
                  </a>

                  <a style={{
                      height:64,
                      display: 'flex',
                      justifyContent: 'start',
                      textDecoration: 'none',
                  }} href="/roadmap">
                      <SizedBox width=".3rem"/>
                      <h6 style={{
                          color: '#fff',
                          lineHeight: '.64rem',
                          textTransform: 'uppercase',
                      }}>Roadmap</h6>
                  </a>

                  <a style={{
                      height:64,
                      display: 'flex',
                      justifyContent: 'start',
                      textDecoration: 'none',
                  }} href="/faq">
                      <SizedBox width=".3rem"/>
                      <h6 style={{
                          color: '#fff',
                          lineHeight: '.64rem',
                          textTransform: 'uppercase',
                      }}>FAQ</h6>
                  </a>

                  
                  <div style={{
                          height: '.64rem',
                          margin: '0 .3rem',
                          backgroundSize: 'contain',
                      }} >
                          <div style={{
                              height: '36px',
                              borderBottom: 'solid 1px rgba(255,255,255,.3)'
                          }}></div>
                      </div>
                  <a style={{
                      height:64,
                      display: 'flex',
                      justifyContent: 'start',
                      textDecoration: 'none',
                  }} href="https://opensea.io/collection/puggy-squad"  target="_blank" rel="noreferrer">
                      <SizedBox width=".3rem"/>
                      <div style={{
                         width: '.48rem',
                         height: '.48rem',
                          background: `url(${openseaIcon}) center no-repeat`,
                          backgroundSize: 'contain',
                          textIndent: '-9999px',
                      }} >OPENSEA</div>
                      <SizedBox width="15px"/>
                      <h6 style={{
                          color: '#fff',
                          lineHeight: '.64rem',
                          textTransform: 'uppercase',
                          }}>OPENSEA</h6>
                  </a>
                  <a style={{
                      height:64,
                      display: 'flex',
                      justifyContent: 'start',
                      textDecoration: 'none',
                  }} href="https://twitter.com/PuggySquadNFT"  target="_blank" rel="noreferrer">
                      <SizedBox width=".3rem"/>
                      <div style={{
                          width: '.48rem',
                          height: '.48rem',
                          background: `url(${twitterIcon}) center no-repeat`,
                          backgroundSize: 'contain',
                          textIndent: '-9999px',
                      }} >Twitter</div>
                      <SizedBox width="15px"/>
                      <h6 style={{
                          color: '#fff',
                          lineHeight: '.64rem',
                          textTransform: 'uppercase',
                      }}>Twitter</h6>
                  </a>
                

              </div>:<div/>
          }
      </>
  )
}

export default MobileHeader;
