/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../../assets/images/logo.png';
import openseaIcon from '../../../assets/images/opensea.png'
import twitterIcon from '../../../assets/images/twitter.png'
import './index.scss';



// interface Props {
//   to: string,
//   children?: ReactNode,
//   id?: string,
// }

// const NavItem = ({ to, children, id }: Props): JSX.Element => {
//   const goToPage = () => {
//     const dom = document.getElementById(to);
//     if (dom) {
//       dom.scrollIntoView({ behavior: 'smooth' });
//       if (to === 'backers') {
//         setTimeout(() => {
//           dom.scrollIntoView({ behavior: 'smooth' });
//         }, 1000)
//       }
//     }
//   }

//   return (
//     <div className='header-nav-item' id={id} onClick={goToPage}>
//       {children}
//     </div>
//   );
// }


export type NavItemProps = {
  to: string,
  title: string,
}

const NavItem = (props: NavItemProps):JSX.Element=>{
  const {
      to,
      title,
  } = props;

  const location = useLocation();
  let navigate = useNavigate();
  const isActive = location.pathname === to;
  
  return (
      <>

          <div className={['header-nav-item', isActive?"active":''].join(' ')} onClick={()=>{
              if(!isActive)navigate(to)
          }}>
            <div className='content'>
              <div className="img">
                <img className='normal' src={require(`../../../assets/images/icon/${title}_normal.png`).default} alt="" />
                <img className='pressed' src={require(`../../../assets/images/icon/${title}_pressed.png`).default} alt="" />
              </div>
            </div>
          </div>
      </>
  )
}


const Header = (): JSX.Element => {
  
  return (
    <>
      <div className='header'>
        <div className='header-blur' />
        <div className='header-wrap'>
          <div className='header-logo'>
            <Link to={'/'}>
              <img src={Logo} alt='Logo' />
            </Link>
          </div>
          <nav className='header-nav'>
            <NavItem to={'/'} title="home"/>
            {/* <NavItem to={'/planet'} title="planet"/> */}
            <NavItem to={'/mint'} title="mint"/>
            <NavItem to={'/roadmap'} title="roadmap"/>
            <NavItem to={'/faq'} title="qa"/>
            {/* <NavItem to={'/mint'} title="mint"/> */}
            
                    {/* <SizedBox width="24px"/> */}
                    <a style={{
                        width: '.64rem',
                        height: '.64rem',
                        background: `url(${twitterIcon}) center no-repeat`,
                        backgroundSize: 'contain',
                        textIndent: '-9999px',
                        marginRight: '.4rem'
                    }} href="https://twitter.com/PuggySquadNFT"  target="_blank" rel="noreferrer">twitter</a>
                    <a style={{
                        width: '.64rem',
                        height: '.64rem',
                        background: `url(${openseaIcon}) center no-repeat`,
                        backgroundSize: 'contain',
                        textIndent: '-9999px',
                    }} href="https://opensea.io/collection/puggy-squad"  target="_blank" rel="noreferrer">opensea</a>
                    {/* <SizedBox width="20px"/> */}
          </nav>
        </div>
      </div>
    </>
  );
}

export default Header;
