import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/Home';
import HttpPage from './pages/Request';
import Planet from './pages/planet/Planet';
import RoadMapComp from './pages/mindmap';
import FAQComp from './pages/faq';
import MintComp from './pages/mint';
import TestPage from './pages/test';
import { useEffect, useRef } from 'react';
import volOpen from './assets/images/vol_open.png';
import volClose from './assets/images/vol_close.png'

function App() {
  const bgVol = useRef<HTMLAudioElement>(null)
  const volBtn = ()=>{
    if(bgVol.current){
      var sta = bgVol.current.paused;
      console.log('%c🀄︎ sta', 'color: #0088cc; font-size: 20px;', sta);
      var ele = document.getElementById('volbtn')
      if(ele !== null){
        if(sta === true){
          // bgVol.current.muted = false;
          bgVol.current.play()
          // ele.setAttribute('background-image': `url(${volClose})`)
          ele.style.backgroundImage = 'url(' + volOpen + ')';

        } else {
          // bgVol.current.muted = true;
          bgVol.current.pause()
          // ele.setAttribute('background-image': `url(${volOpen})`)
          ele.style.backgroundImage = 'url(' + volClose + ')';
        }
      }

    }
  }

  const musicList = useRef([require('./assets/images/TranceFestival-FULL.mp3').default,require('./assets/images/CyberMenace-FULL.mp3').default])

  useEffect(()=>{
   
    var src = musicList.current.pop();
    // @ts-ignore
    bgVol.current.src =src;
    // @ts-ignore
    musicList.current.unshift(src);
    // @ts-ignore
    bgVol.current.addEventListener("ended",playEndedHandler,false);
  })

  const playEndedHandler = ()=>{
    const src = musicList.current.pop();
    // @ts-ignore
    bgVol.current.src =src;
    // @ts-ignore
    musicList.current.unshift(src);
    // @ts-ignore
    bgVol.current.play();
}
  return (
    <>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/planet" element={<Planet />} />
        <Route path="/roadmap" element={<RoadMapComp />} />
        <Route path="/faq" element={<FAQComp />} />
        <Route path="/mint" element={<MintComp />} />
        <Route path="/http" element={<HttpPage />} />
        {/* <Route path="/test" element={<TestPage />} /> */}
        
      </Routes>
      <ToastContainer theme="dark" autoClose={3000} hideProgressBar />
    </Router>
    <audio 
        ref={bgVol} 
        style={{
          position:'fixed',
          top: 0,
          left: '0',
          zIndex:-999999,
          visibility:'hidden'
        }}  
        controls
        x-webkit-airplay="deny"
      >
        {/* <source
          src={require('./assets/images/TranceFestival-FULL.mp3').default}
          type="audio/mpeg"
        />
      <source
          src={require('./assets/images/CyberMenace-FULL.mp3').default}
          type="audio/mpeg"
        /> */}
      </audio>
      <div
      onClick={volBtn}
      id='volbtn'
      style={{
        position: 'fixed',
        right: '20px',
        bottom: '50px',
        width: '44px',
        height: '44px',
        borderRadius: '50%',
        zIndex: 99999,
        background: `url(${volClose}) no-repeat center`,
        backgroundSize: '100%',
      }}
      >
      </div>
    </>
  );
}

export default App;
