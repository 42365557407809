import { toast } from "react-toastify";

export interface DateCount {
  day: number,
  hour: number,
  minute: number,
  second: number,
}

export const dateCount = (endTime: number): DateCount => {
  const now: number = new Date().getTime();
  const until: number = endTime;

  if (until - now <= 0) {
    return {
      day: 0,
      hour: 0,
      minute: 0,
      second: 0,
    }
  }

  const days: number = (until - now) / 1000 / 3600 / 24;
  const day: number = Math.floor(days);
  const hours: number = (days - day) * 24;
  const hour: number = Math.floor(hours);
  const minutes: number = (hours - hour) * 60;
  const minute: number = Math.floor(minutes);
  const seconds: number = (minutes - minute) * 60;
  const second: number = Math.floor(seconds);
  const count: DateCount = {
    day,
    hour,
    minute,
    second,
  }
  return count;
}

export const getPlaceholderStr = (str: string): string => {
  if (!str) return '';
  return `${str.substring(0, 5)}****${str.substring(str.length - 4, str.length)}`
}

export const sleep = async (time: number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  })
}

// 0: error
// 1: success
export const showToast = (str: string, type: number) => {
  // toast.dismiss();
  toast.clearWaitingQueue();
  switch (type) {
    case 0:
      toast.error(str);
      break;
    case 1:
      toast.success(str);
      break;
    default:
      break;
  }
}
