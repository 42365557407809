import joinDiscordImage from '../../assets/images/joindiscord.png'
import Header from "../../components/Header/PC";
import { isMobile } from "../../libs/userAgent";
import MobileHeader from "../../components/Header/Mobile";
import SwiperCore, { Autoplay, EffectCreative } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper-bundle.css"
import "./index.scss";
// import volOpen from '../../assets/images/vol_open.png';
// import volClose from '../../assets/images/vol_close.png'
import { useState } from 'react';


const Body = (): JSX.Element => {
  return (
    <>
    <div className="home">
      <div className="top">
        <div className="videoBox">
        <video  
          preload="auto" 
          loop 
          muted 
          playsInline 
          autoPlay 
          style={{
            position:'absolute',
            width:'100%',
            left:'0',
            bottom:'0',
            // display:'none'
          }}
          // style={{
          //     height: '100%',
          //     width: '177.77777778vh',
          //     minWidth: '100%',
          //     minHeight: '56.25vw', 
          //     position: 'absolute',
          //     left: '50%', 
          //     top: '50%',
          //     display:'none',
          //     transform: 'translate(-50%, -50%)',
          // }}
        >
            <source src={require('../../assets/images/bg.mp4').default} type="video/mp4" />
          </video>
          <a style={{
              display: 'block',
              width: '14rem',
              height: '2rem',
              position: 'absolute',
              left: '50%',
              bottom: 0,
              transform: 'translate(-50%, 0)',
              cursor: 'pointer',
              background: `url(${joinDiscordImage}) bottom no-repeat`,
              backgroundSize: 'contain',
              // textIndent: '-9999px',
          }} 
          href="/planet" 
          rel="noreferrer">
          <div style={{
              position: 'absolute',
              left: '12vw',
              bottom: '0',
              color: '#fff',
              fontSize: '.26rem',
              fontFamily: 'MERegular',
              height: '6vw',
              lineHeight: '5.2vw',
          }}>Whitelist is ongoing...</div>
          </a>
        </div>
      </div>
      <div className="bottom">
        {/* <div className="line"></div> */}
        <div className="left"></div>
        <div className="right"></div>
        <div className="title">
          <p>In this chaotic universe where venom spreads, five puppies with very different lives, from acquaintance to fighting side by side, came together due to various coincidences. After all the ordeals, the five pugs form a team and vow to save the world together.</p>
        </div>
        <ul className="list">
          <li className="item">
            <div className="pic">
              <img src={require('../../assets/images/dog1.png').default} alt="" />
            </div>
            <div className="text">
              <h6 className="name">Rapper Puggy</h6>
              <p className="des">
              An avowed cannabis smoker, making it one of his image's trademarks, created his own eSports league known as the "Gangsta Gaming League."
              </p>
            </div>
          </li>
          <li className="item">
            <div className="pic">
              <img src={require('../../assets/images/dog2.png').default} alt="" />
            </div>
            <div className="text">
              <h6 className="name">Hacker Puggy</h6>
              <p className="des">
              Once one of the most wanted hackers. Hacked 40+ major companies just for the challenge and not for the money.
              </p>
            </div>
          </li>
          <li className="item">
            <div className="pic">
              <img src={require('../../assets/images/dog3.png').default} alt="" />
            </div>
            <div className="text">
              <h6 className="name">Artist Puggy</h6>
              <p className="des">
              A poor painter with a bumpy road in love, his artistic paintings were not recognized during his lifetime, and he even cut off his ear in an incident.
              </p>
            </div>
          </li>
          <li className="item">
            <div className="pic">
              <img src={require('../../assets/images/dog4.png').default} alt="" />
            </div>
            <div className="text">
              <h6 className="name">Clown Puggy</h6>
              <p className="des">
              A clown who is good at disguising himself can bring joy and pain to the world. Possesses superpowers of shapeshifting and manipulating, also good at exploiting the fears of creatures.
              </p>
            </div>
          </li>
          <li className="item">
            <div className="pic">
              <img src={require('../../assets/images/dog5.png').default} alt="" />
            </div>
            <div className="text">
              <h6 className="name">Trainer Puggy</h6>
              <p className="des">
              A disciplined trainer puggy with a perfect body usually chooses to fast for 12 hours a day (overnight).
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </>
  );
}

SwiperCore.use([Autoplay, EffectCreative]);

interface RaceDataItem {
  name: string,
  des: string,
}

const RaceData: RaceDataItem[] = [
  {
    name: 'Rapper Puggy',
    des: `An avowed cannabis smoker, making it one of his image's trademarks, created his own eSports league known as the "Gangsta Gaming League."`
  },
  {
    name: 'Hacker Puggy',
    des: 'Once one of the most wanted hackers. Hacked 40+ major companies just for the challenge and not for the money.'
  },
  {
    name: 'Artist Puggy',
    des: 'A poor painter with a bumpy road in love, his artistic paintings were not recognized during his lifetime, and he even cut off his ear in an incident.'
  },
  {
    name: 'Clown Puggy',
    des: 'A clown who is good at disguising himself can bring joy and pain to the world. Possesses superpowers of shapeshifting and manipulating, also good at exploiting the fears of creatures.'
  },
  {
    name: 'Trainer Puggy',
    des: 'A disciplined trainer puggy with a perfect body usually chooses to fast for 12 hours a day (overnight).'
  }
]

const MobileBody = (): JSX.Element => {
  const [swiperContral, setswiper] = useState<any>({})
  const [currenntData, setcurrentData] = useState<RaceDataItem>({
    name: 'Human',
    des: 'Super friendly, hard-working, and emotional.'
  });
  return(
    <>
      <div className="mobilebody">
        <div className="top">
          <div className="text">
          In this chaotic universe where venom spreads, five puppies with very different lives, from acquaintance to fighting side by side, came together due to various coincidences. After all the ordeals, the five pugs form a team and vow to save the world together.
          </div>
        </div>
        <div className="original">
          <div className="original-swiper">
            <Swiper
              grabCursor
              effect="creative"
              slidesPerView={2}
              centeredSlides
              // autoplay={{ delay: 2000, disableOnInteraction: false }}
              onInit={(el) => {
                setswiper(el);
                console.log(swiperContral)
                const data = RaceData[el.realIndex];
                setcurrentData(data);
              }}
              loop
              loopedSlides={3}
              allowTouchMove
              slideToClickedSlide
              creativeEffect={{
                // limitProgress: 3,
                shadowPerProgress: true,
                perspective: true,
                prev: {
                  translate: ["-50%", 0, 0],
                  // scale: 0.8,
                },
                next: {
                  translate: ["50%", 0, 0],
                  // scale: 0.8,
                },
              }}
              onSlideChange={(el) => {
                const data = RaceData[el.realIndex];
                if (el.realIndex <= 7) {
                  setcurrentData(data);
                }
              }}
            >
              {
                RaceData.map((el, i) => {
                  return <SwiperSlide key={i}>
                    {({ isActive }) => (
                      <div className='original-swiper-content'>
                        <img src={require(`../../assets/images/dog${i+1}.png`).default} alt="" width={'100%'} height={'100%'}/>

                      </div>
                    )}
                  </SwiperSlide>
                })
              }
            </Swiper>
          </div>
          <div className="original-info">
            <div className="original-info-title">
              {currenntData.name}
            </div>
            <div className="original-info-detail">
              {currenntData.des}
            </div>
          </div>
        </div>
        <div className="btn_bottom">
          <a href='/planet'>Visit Planet</a>
        </div>
      </div>
    </>
  )
}


const Home = (): JSX.Element => {

  return (
    <>
      
      {
        isMobile()?
        <>
        <MobileHeader></MobileHeader>
        <MobileBody/>
        </>
        :<>
        <Header></Header>
        <Body></Body>
        </>
      }
    
      
    </>
  );
}

export default Home;
