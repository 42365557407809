/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useRef } from "react";
import { useState } from "react";
import './index.scss'


import { Sizeddiv } from "../../components/base";
import Header from "../../components/Header/PC";
import Scroll from "./srollprops";
import { linkWallet, NxWeb3 } from "../../libs/NxWeb3";
// import QRCodeModal from "@walletconnect/qrcode-modal";
import { Transition } from 'react-transition-group';
import { isMobile } from "../../libs/userAgent";
import MobileHeader from "../../components/Header/Mobile";
// import axios from "axios";
// import { toast } from "react-toastify";
// import _ from "lodash";
// import { google } from "googleapis";
import { GoogleSpreadsheet } from "google-spreadsheet";



export type TapBtnProps = {
    isActive: boolean,
    onClick?: React.MouseEventHandler<HTMLDivElement> | undefined,
}

const TapBtn = (props: TapBtnProps):JSX.Element=>{
    const {
        isActive,
        onClick,
    } = props;
    return (
        <div style={{
            width: '8px',
            borderRadius: '4px',
            height: '80px',
            backgroundColor: isActive? '#2CFF98' : '#00000088',
            cursor: 'pointer',
        }} onClick={onClick}></div>
    )
}

type tapState = 0 | 1 | 2 | 3

const textStyle = {
    color: 'rgba(255, 255, 255)',
    fontSize: '.32rem',
    fontFamily: 'chalkboard',
    lineHeight:'.4rem',
};
const titleStyle = {
    fontFamily:' chalkduster',
    color:'#A8D612',
    fontSize:'.5rem',
    lineHeight:'1.4',
    marginBottom:'.1rem'
}
type ContentProps = {
    ref?: React.Ref<unknown>,
}

const addressArr = [
	"0x23aFA861ED353e61b7F4fFe65204A4B36685E5c8","0x7C9cbA4E3c123817f14219a8DeC31bDf110F5aF4","0x7adcF1dC63E59d50f37c27251F22A26EE319f282","0x8a20c60B93ae6c0a7fb821F8b4000138a55Af977","0xd918252c46bf5D399Ce827151B422810388c79ec","0xEDb8dA4F96b2B336B07EdB6A858D36f48aB046A2","0x7C428BbB875FD890A27A358D9e59e02B0b4d8750","0x37319DA0aDB7eb68787d29Ceb1fc3fd42F816dD2","0x51ec2b772326d976Acc1648c11DD22C233D3A487","0x01965883B5F156019392A21049d9136D10f2F4d5","0x0AE6C833121d9757ddFA3586b49981B51F73F996","0x8d1BCED2A1fdcb9F5255b454695854ce6f381055","0x7913Ef1B288d3eF4D72c4EAa183dc64a2F81B6d0",
	"0x7C428BbB875FD890A27A358D9e59e02B0b4d8750","0x37319DA0aDB7eb68787d29Ceb1fc3fd42F816dD2","0x51ec2b772326d976Acc1648c11DD22C233D3A487","0x01965883B5F156019392A21049d9136D10f2F4d5","0x0AE6C833121d9757ddFA3586b49981B51F73F996","0x8d1BCED2A1fdcb9F5255b454695854ce6f381055","0x7913Ef1B288d3eF4D72c4EAa183dc64a2F81B6d0",
	"0x7C428BbB875FD890A27A358D9e59e02B0b4d8750","0x37319DA0aDB7eb68787d29Ceb1fc3fd42F816dD2","0x51ec2b772326d976Acc1648c11DD22C233D3A487","0x01965883B5F156019392A21049d9136D10f2F4d5","0x0AE6C833121d9757ddFA3586b49981B51F73F996","0x8d1BCED2A1fdcb9F5255b454695854ce6f381055","0x7913Ef1B288d3eF4D72c4EAa183dc64a2F81B6d0",
]


const Listitems = (props: ContentProps):JSX.Element=>{
	return (
		<>
		{
			addressArr.map((address:string,index:number) =>
				<p className="item"
                    key={index}
					style={{
						width: '50%',
						color:'#fff',
						fontSize: '.22rem',
						lineHeight:'.40rem',
						fontFamily:'MERegular'
					}}
				>
					{address.substr(0,10)+"****"+address.substr(address.length-4)},
				</p>
			)
		}
		</>
	)
}



const Tap0Content = (props: ContentProps):JSX.Element=>{
    return (
        <div  style={{display: 'flex',
        minHeight: 'calc(100vh - 90px)',
        flexDirection: 'column',
        justifyContent: 'center',
        lineHeight:'.4rem',}}>
            <div>
                <div style={textStyle}>
                <span style={titleStyle}>Rules:</span><br/>
                Guess the reality character corresponding to the puggy below according to the character description on the homepage, then you will have chance to be a member of our whitelists

                </div>
                <br/>
                <div style={textStyle}>
                <span style={titleStyle}>Tips:</span><br/>
                Check homepage and offical twitter for clues.<br/>There is only one correct answer.
                </div>
            </div>
        </div>
    )
}
declare var window: any
const duration = 300;
const transitionStyles:any = {
    entering: { opacity: 1, zIndex: 999999 },
    entered:  { opacity: 1, zIndex: 999999 },
    exiting:  { opacity: 0, zIndex: -1 },
    exited:  { opacity: 0, zIndex: -1 },
  };
  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
    zIndex: -1,
  }

//   import KeyFiles from './keyFiles.json'

const Tap1Content = (props: ContentProps):JSX.Element=>{
    // const [showSelectBag, setShowSelectBag] = useState(false)
    const [modelShow, setModelShow] = useState(false)
    const [bagAddress, setBagAddress] = useState('')
    const selectType = useRef('')
    const [isTrue, setIsTrue] = useState(false)
    const [trueAnsWer] = useState('l')
    const [activeGame] = useState(5)
    // const addressArr = useRef([])
    const bgColor = ['AA7C73', '2F4760','6E7C8A', '7255D4', '586577']
    const SPREADSHEET_ID:any = '1XJAoyA55jIRuE_Yy_3vuhPWI8MPyKxFbkGNX4vI4ggs';
    const SHEET_ID:any = 0;
    const CLIENT_EMAIL:any = 'submitaddress@bionic-spot-363106.iam.gserviceaccount.com';
    const PRIVATE_KEYs:any = '-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQDVpZXnNiPl+IGh\nftq2DY8oMsHl5t62vqp8DQiKfkBNkpy000NdhoyHmGFWlqNr3YqIC9Unw1vAdg/M\nHkGRmZhNxQn/32DsYfABmK+FM2r6trzh9lzdh1xBi0xRiScjp23PIlp1fO03gMba\nYYW7BgqeFkKFCJJqPtML9J5WXs/CHIbW3LP+MQW4QjpvmXAYIYUa6QmEppR8IiWA\nF9tmwK/7l9oWkRn+/I6y0U3NQHWga1qUFcTkFmbiJawCOsroKatlThKKChecqBpR\nQGbm4E4TNfDT3tMdifwg1O8uXe2lWaTsrgw9JBESkcuWHRQNUQoZPxkeslFS9bzT\nUXH7D4YLAgMBAAECggEABJEDJjnhPW/jjzON6y/KyMFIiMv+zt6wMQ5d0LOLrId0\nQcmf+jTGWbq1gee7Bc9OFUPjewH710juuUjyDEb6RddENDn81Ol+F6jdeG8LBm+3\nd11ldTAIsqkOjKcnCnQpWT8Lul7rT/hHALF8tNBQWuTir1F36z02FAk6k2HNaB4i\nn1i8WZXGjL2hciOOa/gLnrUjhAPcfqM0m+nb+mBi4OtQX7P3S66K8D0Lb1kOEaUL\n/CoDjA9bggQeSgoGfs4Uv4DxCnv+Rt53ZRTWaCV6ZDWCim248OBywutvvhUEQ2bb\nV9VuZ8JWOZzWK376ggK+5jjb5hmdteaQWik+I/jmKQKBgQD5ndjxI7bpvSrzeWZ5\nxfUqzYFQM7AbdRx+SSQMSpsodWCbZF3CVzJbrnsWUy2MP55RLQ1Admki1e3hW9dr\nhv0Z+SChvO5+TuJV94CRXZVNAQ06UWUXMVybhI/BI6yteVNBVzJObPFa25JZOA4V\nuUIbmeF0upg9quHREZbehjo7NQKBgQDbHEGwhOym8XQAcJq0naB6SgR8xpaoAYiF\npahFAVEE64dcMqgAbGx+qCZak05fiKQLmPyW5L/X/b89KxeszCSrXlhEsxbe23mx\nQ6JSPU7YZNfwqFGaOgVb7wW5TuyvRZa7OJVNyjbbbMxGOM9qA5RHSDKM9xXeDirx\nSiipXjmkPwKBgEzfbss8m306c7rNvWBtNYa8CUIA2RBrrZ26uq5KX6RDGcBhVXqf\nTfPsNR33+cQCZ6hKPpWGofRHe932Yc5MoS/ZiFInFC+z7YJaGa8q6erwEvnNzVsq\nKYH/fiIV8XzvK1OpHeYHKQE5M8XkPHo2NoS3jDZdrLr5IFg5mYPoRJCtAoGAbakW\nt2HPjz4I8K9qvjqlto6U8F3zyOH7kef275JZGj1G4b34lz2sjzdY+rv+3ZFliOho\ngpy55z/4FoUatED3mUz+oGOBbsgpf6557u8AJw1RhgC31phzVi+qjbQSgSYy6kDH\neMdb7TXmhr6oEEb6TzKzGhoPt7MfiuOythGSh8ECgYAzh9om7ikFt56NBi6RLxBo\nwTzuUWbObJZnzujPcyf8LHY612T0jZWj1Mp3qNGFYIxcXB2zC/EjchaH3Leo6PeV\nbE/Co26vVzw1e8KlP94fYgKS1OrS2SSN/1H6KrIgjixqR8o0J5Mo5t4ATnV2lz1s\n08Zq3A2+cN8/7ScbS9pgig==\n-----END PRIVATE KEY-----\n';
    const PRIVATE_KEY = PRIVATE_KEYs.replace(/(\\r)|(\\n)/g, '\n')
    const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

    const appendSpreadsheet = async (row: any) => {
        try {
          await doc.useServiceAccountAuth({
            client_email: CLIENT_EMAIL,
            private_key: PRIVATE_KEY,
          });
          // loads document properties and worksheets
          await doc.loadInfo();
      
          const sheet = doc.sheetsById[SHEET_ID];
          console.log('%c🀆 sheet', 'color: #807160; font-size: 20px;', sheet);
          const result = await sheet.addRow(row)
          console.log('%c🀅 result', 'color: #731d1d; font-size: 20px;', result);
        } catch (e) {
          console.error('Error: ', e);
        }
      };

    const handleSubmit = async (order: string) => {
        const newRow:any = { address: bagAddress, time: new Date().toLocaleString() };
        if(order ===trueAnsWer){
            newRow.answer = 'true'
            
        } else {
            newRow.answer = 'false'
        }

        
		await appendSpreadsheet(newRow)
	};


    const selectBag = async (order:string) => {
        

        if(bagAddress !== ''){
         
            setIsTrue(false)
            setModelShow(true)
            await handleSubmit(order)
        } else {
            selectMastMask()
        }
      }

    //   const getItemData = async ( item: any, index: number) => {
    //     let data = ''
    //     if (item[index] !== null) {
         
    //         data = item[index]
      
    //     }
    //     return data
    //   }
      
    // const getAaddress = async ()=>{
    //     await axios
	// 		.get(
	// 			'https://sheets.googleapis.com/v4/spreadsheets/1XJAoyA55jIRuE_Yy_3vuhPWI8MPyKxFbkGNX4vI4ggs/values/whitelist?key=AIzaSyBrc3lEGhVqqq4l3GexrVPOEEqh_pSMzSw'
	// 		)
	// 		.then(async (response) => {

    //             const values = response.data.values;
    //             const headers = values[0]
    //             const array = _.drop(values, 1)
    //             let resultData:any = [] 
    //             for (let ord = 0; ord < array.length; ord++) {
    //               const item = array[ord];
    //               let params:any = {}
          
    //               for (let index = 0; index < headers.length; index++) {
    //                 const ele = headers[index];
    //                 params[ele] = await getItemData(item, index)
    //               }
    //               resultData.push(params)
    //             }

    //             addressArr.current = resultData
	// 		}).catch((err)=>{
    //             toast.error(err)
    //         });
    // }
    useEffect(() => {

        

        if(window.ethereum !== undefined){
            window.ethereum.on('accountsChanged', (accounts: string | any[]) => {

                if (accounts.length === 0) {
    
                // No accounts, it's locked, need take the initiative to disconnect
                // deactivate()
                }
                setBagAddress(accounts[0])
            })
        }
       
        // selectMastMask()
    },[])

    // useEffect(()=>{
    //     getAaddress()
    // },[])


 
    
      const selectMastMask = async () => {
        // setShowSelectBag(false)
        selectType.current = 'MetaMask'
        await linkWallet(() => {
          console.log(NxWeb3.instance.accountAddress)
        })
        await setBagAddress(NxWeb3.instance.accountAddress)
      }

    

    return (
       <>
        <div className="game_block">
            <div className="game_box">
                {/* <div>{bagAddress}</div> */}
                <div className="content">
                <ul className="list">
                        <li 
                        
                            className="item" 
                            onClick={()=>{selectBag('l')}}
                            style={{
                                backgroundColor: `#${bgColor[activeGame -1]}`
                            }}
                        >
                            <img src={require(`../../assets/images/game/game_${activeGame}_l.png`).default} alt="" />
                        </li>
                        <li 
                        
                            className="item" 
                            onClick={()=>{selectBag('m')}}
                            style={{
                                backgroundColor: `#${bgColor[activeGame -1]}`
                            }}
                        >
                            <img src={require(`../../assets/images/game/game_${activeGame}_m.png`).default} alt="" />
                        </li>
                        <li 
                        
                            className="item" 
                            onClick={()=>{selectBag('3')}}
                            style={{
                                backgroundColor: `#${bgColor[activeGame -1]}`
                            }}
                        >
                            <img src={require(`../../assets/images/game/game_${activeGame}_r.png`).default} alt="" />
                        </li>
                    </ul>
                </div>
                <div className="game_bg"></div>
            </div>
        </div>
         <Transition in={modelShow} timeout={duration}>
         {state => (
           <div className="LitepaperModel"
           style={{
             ...defaultStyle,
             ...transitionStyles[state]
           }}
           >
             <div className="content">
               <div className="close" onClick={()=>{setModelShow(false)}}></div>
               {
                   isTrue
                   ?<>
                    <div className="imgBox">
                    <img src={require(`../../assets/images/game/game_${activeGame}_${trueAnsWer}.png`).default} alt="" />
                    </div>
                    <div className="trueAnsWer">
                    correct answer！
                    </div>
                   </>
                   :<div className="text">
                   <p>
                   Thanks for your participating!<br/>Tips: The clues of right<br/> answer is on  Twitter <br/>@PuggySquadNFT.
                   </p>
                   <button onClick={()=>{setModelShow(false)}}>Woof woof…</button>
               </div>
               }
               
             </div>
           </div>
         )}
        
         </Transition>
       </>
    )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Tap2Content = (props: ContentProps):JSX.Element=>{
    return (
        <div style={{display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
        justifyContent: 'center',}}>
					<div className="whiteList">
						<h3 className="title">
							<img src={require('../../assets/images/Whitlist.png').default} alt="" />
						</h3>
						{/* <ul className="list">
							<Listitems/>
						</ul> */}
						<Scroll height={4.4} width={8} speed={60}>
							<Listitems/>
						</Scroll>
					</div>
				</div>
    )
}


const PlanetPc = ():JSX.Element=>{
    
    const [idx, setIdx] = useState<tapState>(0)


    const scrollOuter = useRef<HTMLDivElement>(null);

    return (
        <>
            <div className="planet">
                <div className="newContent">
                    <h1 className="title">Which pug fits the clue description?</h1>
                    <div className="box">
                      <div className="left">
                        <div style={textStyle}>
                          <span style={titleStyle}>Rules:</span><br/>
                          Select the correct role in the box.<br/> Chance to be WL.
                          </div>
                          <br/>
                          <div style={textStyle}>
                          <span style={titleStyle}>Tips:</span><br/>
                          Find Clues on <a href="https://twitter.com/PuggySquadNFT/status/1572646851265925122" target={'_blank'} style={{
                            color: 'rgb(168, 214, 18)',
                            textDecoration: 'underline'
                          }} rel="noreferrer">Twitter</a>
                          </div>
                          <br/>
                          <div 
                            style={textStyle}
                          
                          >
                            <span
                              style={{
                                color: 'rgb(168, 214, 18)'
                              }}
                            >
                               *Click puggy to link MetaMask and
                              start the game after landing;
                              This connection only used for
                              address reading.
                            </span>
                         
                          </div>
                      </div>
                      <div className="right">
                        <Tap1Content></Tap1Content>
                      </div>
                    </div>
                    
                </div>
                

            </div>
        </>
    );
}

const textMobileStyle = {
    color: 'rgba(255, 255, 255)',
    fontSize: '.20rem',
    fontFamily: 'chalkboard',
    lineHeight:'.4rem',
};
const titleMobileStyle = {
    fontFamily:' chalkduster',
    color:'#A8D612',
    fontSize:'.3rem',
    lineHeight:'1.4',
    marginBottom:'.1rem'
}

const PlanetMobile = ():JSX.Element=>{
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isActive, setIsActive] = useState(5)
    return(
        <>
            <div className="PlanetMobile">
                <div className="bg">
                    <img src={require('../../assets/mobile/phone_bg_front.png').default} alt="" />
                </div>
                <div className="container">
                    <div className="top">
                        <div className="text">
                        <div  style={{display: 'flex',
                            // minHeight: 'calc(100vh - 90px)',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            lineHeight:'.4rem',}}>
                                <div>
                                    <div style={textMobileStyle}>
                                    <span style={titleMobileStyle}>Rules:</span><br/>
                                    Guess the reality character corresponding to the puggy below according to the character description on the homepage, then you will have chance to be a member of our whitelists

                                    </div>
                                    <br/>
                                    <div style={textMobileStyle}>
                                    <span style={titleMobileStyle}>Tips:</span><br/>
                                    Check homepage and offical twitter for clues.There is only one correct answer.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="game">
                            <h1 className="title">
                            01<br/> Rapper Puggy
                            </h1>
                            <p>An avowed cannabis smoker, making it one of his image's trademarks, created his own eSports league known as the "Gangsta Gaming League."</p>


                            <Tap1Content />
                        </div>
                    </div>
                    <div className="bottom">
                        <div className="contain">
                            <div className="list">
                            {
                                [1,2,3,4,5].map((el, i) => {
                                return <div className={['item',isActive === el?'active':''].join(' ')} key={i}>
                                        <img src={require(`../../assets/mobile/planet${el}.png`).default} alt="" width={'100%'} height={'100%'}/>
                                </div >
                                })
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}




const Planet = ():JSX.Element=>{
    return (
        <>
            {
            isMobile()? 
            <>
                <MobileHeader/>
                <PlanetMobile/>
            </>
            : 
            <>
                <Header></Header>
                <PlanetPc/>
            </>}
        </>
    );
}

export default Planet;