export const isMobile = (): boolean => {
  let isMobileval: boolean = false;

  const _isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
  if (_isMobile) {
    const sWidth = document.body.clientWidth
    if(sWidth < 750){
      isMobileval = true;
    } else {
      isMobileval = false;
    }
  }
  // window.onresize = function(){
  //   window.location.reload();
  // };

  return isMobileval;
}
