import React from 'react';

interface ScrollProps {
    height: number; // 容器的高度
    width: number;
    speed: number; // 滚动的速度，越大则滚动的越快，反之越慢
}

export default class Scroll extends React.Component<ScrollProps> {
    state = {
        rollClass: ''
    };
    setScrollStyle = (offsetHeight: number, speed: number) => {
        const uid = Math.random().toString(36).substr(2);
        const style = document.createElement('style');
        style.type = 'text/css';

        style.innerHTML = `@-webkit-keyframes rowup${uid} {
            0% {
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }
            100% {
                -webkit-transform: translate3d(0, -50%, 0);
                transform: translate3d(0, -50%, 0);
            }
        }
        @keyframes rowup${uid} {
            0% {
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }
            100% {
                -webkit-transform: translate3d(0, -50%, 0);
                transform: translate3d(0, -50%, 0);
            }
        }
        .rowup-${uid}{
            -webkit-animation: ${Math.floor(offsetHeight*1000 / speed)}ms rowup${uid} linear infinite normal;
            animation: ${Math.floor(offsetHeight*1000 / speed)}ms rowup${uid} linear infinite normal;
        }`
        document.getElementsByTagName('head')[0].appendChild(style);
        return `rowup-${uid}`;
    }

    componentDidMount() {
        const scrollContent: HTMLElement | null = document.querySelector('.scroll .scroll-content');
        if (scrollContent) {
            const offsetHeight = scrollContent.offsetHeight;

            const scrollClass = this.setScrollStyle(offsetHeight / 2, this.props.speed || 35);
            this.setState({
                rollClass: scrollClass
            });
        }
    }
    render() {
        const rollClass = this.state.rollClass ? ' ' + this.state.rollClass : '';
        return (
            <div className="scroll" style={{
                height: `${this.props.height}rem`, 
                width:`${this.props.width}rem`,
                overflow: 'hidden'
            }} >
                <div className={"scroll-content" + rollClass}
                    style={{
                        display:'flex',
                        alignItems: 'center',
                        flexWrap:'wrap',
                        // width:`${this.props.width}rem`,
                    }}
                >
                    {this.props.children}
                    {this.props.children}
                </div>
            </div>
        );
    }
}