import MobileHeader from "../../components/Header/Mobile";
import Header from "../../components/Header/PC";
import { isMobile } from "../../libs/userAgent";
import './index.scss'

const PCFAQ = (): JSX.Element => {
  return(
    <>
      <div className="faq">
        <div className="container">
          <div className="dog">
            <img src={require('../../assets/images/faq_dog.png').default} alt="" />
            <h1 className="title">
            FREQUENTLY ASKED QUESTIONS
            </h1>
          </div>
          <div className="list">
            <div className="item">
              <div className="title">
              WHAT IS PUGGY SQUAD?
              </div>
              <div className="as">
              Puggy Squad is a collection of 3333 unique avatars.<br/> 
              We comprise five different classes of characters from other planets. 
              Collect your favorite characters and join them on a grand adventure on Planet Alpha. 
              Become a hero and save the world right now.
              </div>
            </div>
            <div className="item">
              <div className="title">
              WHAT CHAIN ARE PUGGY SQUAD?
              </div>
              <div className="as">
              Ethereum.
              </div>
            </div>
            {/* <div className="item">
              <div className="title">
              WHEN IS THE MINT PRICE?
              </div>
              <div className="as">
              TBA
              </div>
            </div> */}
            <div className="item">
              <div className="title">
              WHAT IS THE MINT PRICE?
              </div>
              <div className="as">
              WL Free; Public 0.03Eth.
              </div>
            </div>
            <div className="item">
              <div className="title">
                What is the WL mint time?
              </div>
              <div className="as">
              UTC: Mon, Oct17 4:00pm<br/>
              EST: Mon, Oct17 12:00pm
              </div>
            </div>
            {/* <div className="item">
              <div className="title">
              WHERE CAN I VIEW MY NFTS?
              </div>
              <div className="as">
              </div>
            </div> */}
          </div>
          <div className="bottom">
            <div className="l">
              <img src={require('../../assets/images/ftv.png').default} alt=""/>
            </div>
            {/* <div className="right">
              <div className="m">
              <img src={require('../../assets/images/joinouropensea.png').default} alt="" width={'100%'}/>
                
              </div>
              <div className="r">
                <a href="https://twitter.com/PuggySquadNFT"  target="_blank" rel="noreferrer">
                <img src={require('../../assets/images/joinourtwitter.png').default} alt="" width={'100%'}/>
                </a>
              </div>
            </div> */}
          </div>
       
        </div>
      </div>
    </>
  )
}

const MobileFAQ = (): JSX.Element => {
  return(
    <>
      <div className="mobileFaq">
        <div className="container">
          <h1 className="title">
          FREQUENTLY<br/> ASKED<br/> QUESTIONS
          </h1>
          <div className="list">
            <div className="item">
              <div className="title">
              WHAT IS PUGGY SQUAD?
              </div>
              <div className="as">
              Puggy Squad is a collection of 3333 unique avatars.<br/> 
              We comprise five different classes of characters from other planets. 
              Collect your favorite characters and join them on a grand adventure on Planet Alpha. 
              Become a hero and save the world right now.
              </div>
            </div>
            <div className="item">
              <div className="title">
              WHAT CHAIN ARE PUGGY SQUAD?
              </div>
              <div className="as">
              Ethereum.
              </div>
            </div>
            <div className="item">
            <div className="title">
              WHAT IS THE MINT PRICE?
              </div>
              <div className="as">
              WL Free; Public 0.03Eth.
              </div>
            </div>
            <div className="item">
              <div className="title">
                What is the WL mint time?
              </div>
              <div className="as">
              UTC: Mon, Oct17 4:00pm<br/>
              EST: Mon, Oct17 12:00pm
              </div>
            </div>
            {/* <div className="item">
              <div className="title">
              WHERE CAN I VIEW MY NFTS?
              </div>
              <div className="as">
              </div>
            </div> */}
          </div>
          <div className="bottom">
            <div>
              <img src={require('../../assets/images/ftv.png').default} alt="" width={'100%'}/>
            </div>
            {/* <div className="tip">
              <div className="l">
                <img src={require('../../assets/images/joinouropensea.png').default} alt="" width={'100%'}/>
              </div>
              <div className="r">
                <a href="https://twitter.com/PuggySquadNFT"  target="_blank" rel="noreferrer">
                  <img src={require('../../assets/images/joinourtwitter.png').default} alt="" width={'100%'}/>
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}



const FAQComp = (): JSX.Element => {
  return (
    <>
       {
        isMobile()?
        <>
        <MobileHeader></MobileHeader>
        <MobileFAQ/>
        </>
        :<>
        <Header></Header>
        <PCFAQ/>
        </>
      }
      
    </>
  );
}

export default FAQComp;