import MobileHeader from '../../components/Header/Mobile';
import Header from '../../components/Header/PC';
import { isMobile } from '../../libs/userAgent';
import './index.scss'

const PCRoadMap = (): JSX.Element => {
  return (
    <>
      <div >
        <Header/>
        <div className="mindMap">
          <div className="container">
            <h1 className="title">
              {/* <img src={require('../../assets/images/roadmap_title.png').default} alt="" /> */}
              ROADMAP
            </h1>
            <div className="list">
              <div className="item">
                <img src={require('../../assets/images/rm1.png').default} alt="" width={'100%'} height={'100%'}/>
              </div>
              <div className="item">
                <img src={require('../../assets/images/rm2.png').default} alt="" width={'100%'} height={'100%'}/>
              </div>
              <div className="item">
                <img src={require('../../assets/images/rm3.png').default} alt="" width={'100%'} height={'100%'}/>
              </div>
              <div className="item">
                <div className="top">
                  <img src={require('../../assets/images/rm4.png').default} alt="" width={'100%'} height={'100%'}/>
                </div>
                <div className="bottom">
                  <img src={require('../../assets/images/rm5.png').default} alt="" width={'100%'} height={'100%'}/>
                </div>
              </div>
            </div>
            <div className="chapter">
              <div className="chapter_title">
              First Chapter
                {/* <img src={require('../../assets/images/chapter_title.png').default} alt="" /> */}
              </div>
              <p className="des">
              Five pugs from different planets with different occupations and backgrounds signed up for the cosmic blind box tour group by chance, and then they were all transported to a place full of mutant creatures named Alpha.
              </p>
              <div className="comics">
                <img src={require('../../assets/images/comics.png').default} width='100%' alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const MobileRoadMap = (): JSX.Element => {
  return(
    <>
      <div className="mobileRoadmap">
        <div className="container">
          <h1 className="title">ROADMAP</h1>
            <div className="list">
              <div className="item">
                <img src={require('../../assets/mobile/rm1.png').default} alt="" width={'100%'} height={'100%'}/>
              </div>
              <div className="item">
                <img src={require('../../assets/mobile/rm2.png').default} alt="" width={'100%'} height={'100%'}/>
              </div>
              <div className="item">
                <img src={require('../../assets/mobile/rm3.png').default} alt="" width={'100%'} height={'100%'}/>
              </div>
            </div>
            <div className="list_bottom">
              <div className="item">
                <img src={require('../../assets/mobile/rm4.png').default} alt="" width={'100%'} height={'100%'}/>
              </div>
              <div className="item">
                <img src={require('../../assets/mobile/rm5.png').default} alt="" width={'100%'} height={'100%'}/>
              </div>
            </div>
            <div className="chapter">
              <h1 className="chapter_title">First Chapter</h1>
              <p className="des">
              Five pugs from different planets with different occupations and backgrounds signed up for the cosmic blind box tour group by chance, and then they were all transported to a place full of mutant creatures named Alpha.
              </p>
              <div className="comics">
                <img src={require('../../assets/mobile/comics.png').default} width='100%' alt="" />
              </div>
            </div>
        </div>
      </div>
    </>
  )
}

const RoadMapComp = (): JSX.Element => {
  return (
    <>
      
      {
        isMobile()?
        <>
        <MobileHeader></MobileHeader>
        <MobileRoadMap/>
        </>
        :<>
        <Header></Header>
        <PCRoadMap/>
        </>
      }
      
    </>
  );
}

export default RoadMapComp;